import React from "react";
import {Link} from "gatsby";
import {getPostUrl} from "../lib/helpers";
import Img from "gatsby-image/index";
import {format} from "date-fns";
import PortableText from "./portableText";
import * as PropTypes from "prop-types";


class FeaturedArticle extends React.Component {
    render() {
        return <div className='w-full md:px-4 md:mb-12'>
            <Link to={getPostUrl(this.props.node.publishedAt, this.props.node.slug)}
                  className='flex flex-col bg-brand-blue md:flex-row h-full group no-underline hover:shadow-lg transition-all duration-500 ease-in-out bg-white'>
                                                    <span className='flex-1 -mr-20px'>
                                                        <span className='block pb-1/2 relative'>
                                                          <span
                                                              className='absolute top-0 left-0 bottom-0 right-0 overflow-hidden'>
                                                            <Img fluid={this.props.node.mainImage.asset.fluid}
                                                                 className='object-cover transition duration-500 ease-in-out transform group-hover:scale-105'/>
                                                          </span>
                                                        </span>
                                                    </span>
                <span
                    className='w-full md:w-1/3 bg-brand-blue px-8 md:pl-12 md:pr-8 pt-8 pb-12 md:pb-8 text-brand-grey flex flex-col'>
                                                        <h2 className='font-heading text-lg pt-6 font-semibold mb-3 transition-colors duration-500 ease-in-out group-hover:text-white'>
                                                            {this.props.node.title}
                                                        </h2>
                                                        <span className='block mb-3'>
                                                            <span
                                                                className='text-byline font-medium'>{format(this.props.node.publishedAt, "DD.MM.YYYY \Kl. HH:mm")}</span>
                                                        </span>
                                                        <span className='block text-base leading-normal mb-3 flex-1'>
                                                            <PortableText blocks={this.props.node._rawExcerpt}/>
                                                        </span>
                                                    </span>
            </Link>
        </div>;
    }
}

export default FeaturedArticle;