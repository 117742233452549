import React from 'react'
import {graphql, Link} from 'gatsby'
import Container from '../components/container'
import SEO from '../components/seo'
import FeaturedArticle from "../components/card-featured-article";
import ArticleCard from "../components/card-article";

export const query = graphql`
query PostsPageQuery {
    posts: allSanityPost(
        sort: {fields: [publishedAt], order: DESC}
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
        edges {
            node {
                publishedAt
                title
                slug {
                    current
                }
                _rawExcerpt(resolveReferences: {maxDepth: 5})
                mainImage {
                    ...MainImage
                }
                thumbImage: mainImage {
                    ...SquareMainImage
                }
            }
        }
    }
}
`

class PostsPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props
        this.state = {
            posts: (data || {}).posts
        }
    }

    render() {
        return (
            <>
                <SEO
                    title='Blogg'
                />

                <div className='hidden md:flex justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1558.121" height="227.965"
                         viewBox="0 0 1558.121 227.965" className='max-w-full -mt-24 mb-16'>
                        <g transform="translate(-181 -113.5)">
                            <path className="text-brand-grey text-opacity-50 fill-current"
                                  d="M351-1823.232v-25.693H606.164v25.693A127.582,127.582,0,0,1,478.582-1695.65,127.582,127.582,0,0,1,351-1823.232Z"
                                  transform="translate(-170 1962.925)"/>
                            <path className="text-brand-grey text-opacity-50 fill-current"
                                  d="M682.821-1367.559l52.8-96.953c.27-.5.545-1,.82-1.489h767.512q-29.815,72.947-87.793,120.8-128.641,106.167-327.67,106.16Q871.074-1239.035,682.821-1367.559Z"
                                  transform="translate(-170 1580)"/>
                            <path className="text-brand-grey text-opacity-50 fill-current"
                                  d="M1777.461-1695.65c-68.171,0-123.973-54.587-123.973-121.312v-31.959h255.133v31.959c0,66.725-55.8,121.312-123.961,121.312Z"
                                  transform="translate(-170 1962.921)"/>
                        </g>
                    </svg>
                </div>
                <Container>
                    <div className='-mx-6 md:mx-0 md:pb-24'>
                        <div className='flex md:-mx-4 flex-wrap pb-4'>
                            {this.state.posts && this.state.posts.edges.map(({node}, index) => {
                                if (index === 0) {
                                    return (
                                        <FeaturedArticle key={`post-${index}`} node={node}/>
                                    )
                                }
                                return (
                                    <ArticleCard key={`post-${index}`} node={node}/>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default PostsPage
