import React from "react";
import {Link} from "gatsby";
import {getPostUrl} from "../lib/helpers";
import Img from "gatsby-image/index";
import {format} from "date-fns";
import PortableText from "./portableText";

class ArticleCard extends React.Component {
    render() {
        return (
            <div className='w-full md:w-1/3 md:px-4 md:mb-8'>
                <Link to={getPostUrl(this.props.node.publishedAt, this.props.node.slug)}
                      className='block h-full group no-underline transition-all duration-500 ease-in-out md:hover:bg-white md:bg-white md:bg-opacity-50 md:hover:bg-opacity-75'>
                      <span className='flex h-full flex-col text-brand-body'>
                        <span className='h-auto pb-1/2 relative mb-8'>
                          <span className='absolute top-0 left-0 bottom-0 right-0 overflow-hidden'>
                            <Img fluid={this.props.node.mainImage.asset.fluid}
                                 className='object-cover transition duration-500 ease-in-out transform group-hover:scale-105'/>
                          </span>
                            <span
                                className="md:hidden h-10 w-10 bg-brand-red-light text-lg rounded-full flex items-center justify-center text-white absolute right-0 bottom-0 -mb-5 mr-5">
                                <span className="fa fa-chevron-right"/>
                            </span>
                        </span>
                        <span className='flex flex-col px-10 md:px-8 mb-4 flex-1'>
                          <h2 className='font-heading text-xl font-semibold leading-tight tracking-tight mb-7px transition-colors duration-500 ease-in-out group-hover:text-brand-red'>
                              {this.props.node.title}
                          </h2>
                                <span className='block mb-3'>
                                <span
                                    className='text-byline font-medium text-brand-grey-darker'>{format(this.props.node.publishedAt, "DD.MM.YYYY \Kl. HH:mm")}</span>
                              </span>
                            <span className='block text-base leading-snug mb-10 flex-1'>
                                <PortableText blocks={this.props.node._rawExcerpt}/>
                            </span>
                        </span>
                      </span>
                </Link>
            </div>
        );
    }
}

export default ArticleCard;